import * as fabric from 'fabric';

export default class OverlayCanvas {
  constructor() {
    this.enableOverlayCanvasBtn = '#show-overlay-canvas-btn';
    this.enableOverlayCanvasMessage = '#enable-overlay-canvas-message';
    this.disableOverlayCanvasMessage = '#disable-overlay-canvas-message';
    this.overlayCanvasContainer = '.overlay-canvas-container';
    this.overlayCanvas = '#overlay-canvas';
    this.canvas = null;
  }

  setup() {
    this.resizeOverlayCanvasContainer();
    this.initOverlayCanvas();

    $(this.enableOverlayCanvasBtn).on('click', () => {
      this.toggleOverlayCanvas();
      $('.question-section').hide();
    });

    $(window).on('resize', () => this.resizeOverlayCanvasContainer());
  }

  toggleOverlayCanvas() {
    if ($(this.enableOverlayCanvasMessage).is(':visible')) {
      $(this.enableOverlayCanvasMessage).hide();
      $(this.disableOverlayCanvasMessage).show();
      $(this.overlayCanvasContainer).show();
    } else {
      $(this.enableOverlayCanvasMessage).show();
      $(this.disableOverlayCanvasMessage).hide();
      $(this.overlayCanvasContainer).hide();

      this.clearOverlayCanvas();
    }
  }
  
  resizeOverlayCanvasContainer() {
    $(this.overlayCanvasContainer).width($(document).width());
    $(this.overlayCanvasContainer).height($(document).height());
    
    if (this.canvas) {
      this.canvas.setDimensions({
        width: $(document).width(),
        height: $(document).height(),
      });
    }
  }
  
  initOverlayCanvas() {
    this.canvas = new fabric.Canvas(document.querySelector(this.overlayCanvas));
    this.canvas.isDrawingMode = true;
    this.canvas.freeDrawingCursor = 'pointer';
    this.canvas.freeDrawingBrush = new fabric.PencilBrush(this.canvas);
    this.canvas.freeDrawingBrush.width = 5;
    this.canvas.freeDrawingBrush.color = '#00008b';

    this.canvas.setDimensions({
      width: $(document).width(),
      height: $(document).height(),
    });
  }
  
  clearOverlayCanvas() {
    this.canvas.clear();
  }
}

import Raicon from 'raicon';
import initDoughnutChart from '@/layouts/application/utils/charts/initDoughnutChart';
import CashflowChart from './cashflowChart';
import CpfLife from './cpfLife';
import CpfBonus from './cpfBonus';
import { add10Referees } from '../add10Referees';
import { NET_WEALTH, LIABILITY } from '@/layouts/application/utils/charts/chartColors';
import { formatCurrency, formatMoneyToNumber, formatDate } from '@/layouts/application/utils/formats';
import Chart from 'chart.js/auto';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import { DEFAULT_CURRENCY } from '@/constants';
import { debounce } from '@/helpers/index';
import OverlayCanvas from '../../shared/overlayCanvas';

class ConsultantCashflowsController {
  constructor() {
    this.formSelector = '.new-income-modal-body';
    this.cashflowChartDetailContainer = '#cashflow-chart-details-container';
    this.incomeCountryOfWorkSelected = '#country-of-work';
    this.incomeCountryOfWorkSelectedOption = '#country-of-work option';
    this.incomeIsForeignerLabel = '#income_is_foreigner_label';
    this.forHasSingaporePr = '#for_has_singapore_pr';
    this.cpfContributionOnlyShow = '#cpf-contribution-only-show';
    this.incomeAmount = '#income_income_amount';
    this.incomeInterval = '#income_income_interval_type';
    this.employeeContribution = '#employee-contribution';
    this.employerContribution = '#employer-contribution';
    this.incomeEmployeeContribution = '#income_employee_contribution';
    this.incomeEmployerContribution = '#income_employer_contribution';
    this.cashflowProjectionChart = null;
    this.incomeStartDate = '.income-start-date';
    this.incomeEndDate = '.income-end-date';
    this.incomeStartDateAge = '.income-start-date-age';
    this.incomeEndDateAge = '.income-end-date-age';
    this.clientId = $('body').data('client-id');
    this.isForeignerCheckbox = '#income_is_foreigner';
    this.isSelfEmployedSingaporeanCheckbox = '#income_is_self_employed_singaporean';
    this.isSelfEmployedGroup = '#for-self-employed-groups';
    this.hasSingaporePrCheckbox = '#income_has_singapore_pr';
    this.hasSingaporePrLabel = '#income_has_singapore_pr_label';
    this.singaporePrSinceInput = '#singapore-pr-since-input';
    this.currencySelector = '#currency-select';
    this.currencySelectorBusinessSalary = '#currency-select-business-salary';
    this.propertyCurrency = '#property-currency';
    this.propertyCurrencyBusinessSalary = '#property-currency-business-salary';
    this.propertyCurrencyTextSelector = '.property-currency-text';
    this.contributionFormulaApplied = '#contribution-formula-applied';
    this.mainSalaryTab = '#main-salary-income';
    this.userNationalityInfoSelector = '#client-nationality-info';
  }

  index() {
    this.drawCashflowChartMonth();
    this.drawCashflowChartYear();
    this.setupCashflowProjectionChart();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.triggerContentCashflow();
    new CpfLife().setup();
    this.addTriggerOpenModalIncome();
    this.setupGiftingCalculatorForm();
    this.addTriggerOpenModalExpense();
    this.currencyChangeHandler();
    this.setupOverlayCanvas();
    bulkDeleteItem('#bulk-delete-income', '.income-checkbox');
    bulkDeleteItem('#bulk-delete-expense', '.expense-checkbox');
  }

  currencyChangeHandler() {
    $('body').on('change', `${this.currencySelector}, ${this.currencySelectorBusinessSalary}`, (event) => {
      const selectedCurrency = $(event.target).val();
      const isBusinessSalary = $(event.target).is(this.currencySelectorBusinessSalary);
      const propertyCurrencySelector = isBusinessSalary ? this.propertyCurrencyBusinessSalary : this.propertyCurrency;

      if (selectedCurrency === 'SGD') {
        $(propertyCurrencySelector).removeClass('currency-sign-no-content').addClass('currency-sign');
      } else {
        $(propertyCurrencySelector).removeClass('currency-sign').addClass('currency-sign-no-content');
      }

      $(this.propertyCurrencyTextSelector).each(function () {
        let propertyValue = $(this).text();

        // Remove $ sign from digit part
        propertyValue = propertyValue.replace(/\$(\d+(,\d{3})*(\.\d+)?)/g, '$1');

        if (selectedCurrency !== 'SGD') {
          // If the currency is not SGD, just display the number
          $(this).text(propertyValue);
        } else {
          // If the currency is SGD, add the $ sign back
          $(this).text(propertyValue.replace(/(\d+(,\d{3})*(\.\d+)?)/g, '$$$1'));
        }
      });

      // only calculate CPF or EPF for main income form
      if($(this.mainSalaryTab).length > 0) {
        this.ajaxCallToCalculatePrepareMonthlyContribution();
      }
    });
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  setupOverlayCanvas() {
    new OverlayCanvas().setup();
  }

  triggerContentCashflow() {
    $('.nav-link').on('click', (e) => {
      if ($(e.target).parent().hasClass('projection-nav')) {
        $('.cashflow-materials-content').addClass('d-none');
      } else {
        $('.cashflow-materials-content').removeClass('d-none');
      }
    });
  }

  setupCashflowProjectionChart() {
    $('#cashflow-projection-chart-show-btn').on('click', () => {
      const $cashflowChartDetailsContainer = $(this.cashflowChartDetailContainer);
      let chartStatus = Chart.getChart($cashflowChartDetailsContainer);
      if (chartStatus != undefined) {
        return;
      }
      this.cashflowProjectionChart = new CashflowChart($cashflowChartDetailsContainer).setup();
    });
  }

  drawCashflowChartMonth() {
    const $chartElementMonth = $('#cashFlowChartMonth');
    const chartDataMonth = $chartElementMonth.parent().data('calculation');

    if ($chartElementMonth.length > 0) {
      initDoughnutChart($chartElementMonth, chartDataMonth, [NET_WEALTH, LIABILITY]);
    }
  }

  drawCashflowChartYear() {
    // Using to render chart if element is hidden
    const $chartElementYear = $('#cashFlowChartYear');

    $('a[href="#cashflow-year-show"]').on('shown.bs.tab', function () {
      if (!$chartElementYear.hasClass('rendered') && $chartElementYear.length > 0) {
        const nums_months_of_year = 12;
        const chartDataYear = $chartElementYear
          .parent()
          .data('calculation')
          .map((e) => e * nums_months_of_year);

        $chartElementYear.addClass('rendered');
        initDoughnutChart($chartElementYear, chartDataYear, [NET_WEALTH, LIABILITY]);
      }
    });
  }

  ajaxCallToCalculatePrepareMonthlyContribution() {
    if($(this.contributionFormulaApplied).length <= 0) {
      return
    }

    const countryOfWork = $(this.incomeCountryOfWorkSelectedOption).filter(':selected').val();
    let currency = $(this.currencySelector).val() || DEFAULT_CURRENCY;
    let urlPrepareContribution = Routes.prepare_cpf_contribution_consultant_client_income_path

    if (countryOfWork == 'Malaysia') {
      urlPrepareContribution = Routes.prepare_epf_contribution_consultant_client_income_path;
    }

    $.ajax({
      url: urlPrepareContribution(this.clientId, {
        client_id: this.clientId,
        income: $(this.incomeAmount).val(),
        income_interval: $(this.incomeInterval).find(':selected').val(),
        country_of_work: countryOfWork,
        currency: currency,
      }),
      type: 'GET',
      success: (data) => {
        $(this.incomeEmployeeContribution).val(data['employee_contribution'], currency);
        $(this.incomeEmployerContribution).val(data['employer_contribution'], currency);
        $(this.employeeContribution).text(formatCurrency(data['employee_contribution'], currency));
        $(this.employerContribution).text(formatCurrency(data['employer_contribution'], currency));
      },
    });
  }

  checkboxLogicIncomeSalary() {
    const countryOfWork = $(this.incomeCountryOfWorkSelectedOption).filter(':selected');

    if (countryOfWork.val() == 'Malaysia') {
      $('.bonus-options').addClass('d-none');
      $(this.cpfContributionOnlyShow).find('#cpf-epf-monthly-text').text('Monthly EPF Contributions');
      $(this.isSelfEmployedGroup).find('.self-employed-group-label').text('EPF Contribution');

      $('.medisave-contribution label').text('EPF Contribution');
      $('.medisave-contribution input').attr('placeholder', 'EPF Contribution');
      $('.contribution-interval-type label span').text('EPF Contribution Interval Type');
    } else {
      $('.bonus-options').removeClass('d-none');
      $(this.cpfContributionOnlyShow).find('#cpf-epf-monthly-text').text('Monthly CPF Contributions');
      $(this.isSelfEmployedGroup).find('.self-employed-group-label').text('CPF Contribution');

      $('.medisave-contribution label').text('Medisave Contribution');
      $('.medisave-contribution input').attr('placeholder', 'Medisave Contribution');
      $('.contribution-interval-type label span').text('Medisave Contribution Interval Type');
    }
  }

  toggleCheckbox() {
    $('.main-salary-income-form').on('change', () => {
      this.checkboxLogicIncomeSalary();
      new CpfBonus().setup();
    });
  }

  addTriggerOpenModalIncome() {
    $('body').on('modal:afterSetContent', '#new-income-salary, #open-income-salary, #edit_income, #new_income', () => {
      new CpfBonus().setup();
      this.clientId = $('body').data('client-id');
      if ($(this.mainSalaryTab).hasClass('show')) {
        if ($('#income_salary_income_type').val() == 'salary') {
          this.checkboxLogicIncomeSalary();
          this.toggleCheckbox();
          this.loadProvidentFundContribution();
        }
      }

      $('.main-income-type-selector #overview-tab .nav-link').on('click', () => {
        setTimeout(() => {
          if ($(this.mainSalaryTab).hasClass('show')) {
            if ($('#income_salary_income_type').val() == 'salary') {
              this.checkboxLogicIncomeSalary();
              this.toggleCheckbox();
              this.loadProvidentFundContribution();
            }
          } else {
            $(this.formSelector).off('input');
          }
        }, 165);
      });

      $('#career-main-income').on('click', () => {
        $('#quiz_career_salary_income_form_modal').removeClass('d-none');
        $('#main-modal .income-modal').css('opacity', '0');
      });

      $('#hide-quiz-career-modal img').on('click', () => {
        $('#quiz_career_salary_income_form_modal').addClass('d-none');
        $('#main-modal .income-modal').css('opacity', '1');
      });

      $('#check_send_mail').on('click', () => {
        if ($('#check_send_mail').is(':checked')) {
          $('#send-mail-create-quiz-to-contact').removeClass('disabled');
        } else {
          $('#send-mail-create-quiz-to-contact').addClass('disabled');
        }
      });

      this.calculageAgeOnDateChange();
      this.calculateIncomeStartDateAge();
      this.calculateIncomeEndDateAge();
      this.autoFillCheckboxWhenChooseClientToSyncData();

      $(this.currencySelector).trigger('change');
      $(this.currencySelectorBusinessSalary).trigger('change');
    });

    $('body').on(
      'modal:afterSetContent',
      '#open-property-asset, #new-rental-income, #new-investment-payout, #new-other-income',
      () => {
        $(this.currencySelector).trigger('change');
      },
    );
  }

  loadProvidentFundContribution() {
    if ($(this.incomeAmount).val() == '0') {
      let currency = $(this.currencySelector).val() || DEFAULT_CURRENCY;
      $(this.employeeContribution).text(formatCurrency('0', currency));
      $(this.employerContribution).text(formatCurrency('0', currency));
    } else {
      this.ajaxCallToCalculatePrepareMonthlyContribution();
    }

    $(this.incomeCountryOfWorkSelected).on(
      'change',
      () => {
        this.ajaxCallToCalculatePrepareMonthlyContribution();
      },
    );

    $(this.formSelector).on('input', `${this.incomeAmount}, ${this.incomeInterval}`, debounce(() => {
      this.ajaxCallToCalculatePrepareMonthlyContribution();
    }, 500));
  }

  addTriggerOpenModalExpense() {
    $('body').on(
      'modal:afterSetContent',
      '#new-expense-living, #new-expense-rental, #new-expense-maintenance, #new-expense-happy-fund, #new-expense-subscription, #new-expense-gifting, #new-expense-other, #open-property-asset',
      () => {
        let initialValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
        $('#age-value').html(initialValue);

        $('#expense_end_date').on('change', () => {
          let ageValue = getAge($('#date-of-birth-data').data('dob-data'), new Date($('#expense_end_date').val()));
          $('#age-value').html(ageValue);
        });

        $(this.currencySelector).trigger('change');
      },
    );
  }

  calculageAgeOnDateChange() {
    $(this.incomeStartDate).on('change', ({ target }) => {
      const startDate = new Date(target.value);
      if (startDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), startDate);
      $(this.incomeStartDate).val(formatDate(startDate));
      $(this.incomeStartDateAge).html(age);
      if ($(this.incomeEndDate).val() == '') {
        const retirementDate = new Date($(this.incomeStartDate).data('retirement-date'));
        const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), retirementDate);
        $(this.incomeEndDate).val(formatDate(retirementDate));
        $(this.incomeEndDateAge).html(age);
      }
    });

    $(this.incomeEndDate).on('change', ({ target }) => {
      const endDate = new Date(target.value);
      if (endDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), endDate);
      $(this.incomeEndDate).val(formatDate(endDate));
      $(this.incomeEndDateAge).html(age);
    });
  }

  calculateIncomeStartDateAge() {
    const startDate = $(this.incomeStartDate).val();
    if (startDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(startDate));
      $(this.incomeStartDateAge).html(age);
    }
  }

  calculateIncomeEndDateAge() {
    const endDate = $(this.incomeEndDate).val();
    if (endDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(endDate));
      $(this.incomeEndDateAge).html(age);
    }
  }

  autoFillCheckboxWhenChooseClientToSyncData() {
    $('.sync-to-client-radio').on('change', ({ target }) => {
      const incomeIsForeigner = $(this.isForeignerCheckbox);
      const incomeIsSelfEmployedSingaporean = $(this.isSelfEmployedSingaporeanCheckbox);
      const incomeHasSingaporePr = $(this.hasSingaporePrCheckbox);
      const singaporePrSinceInput = $(this.singaporePrSinceInput);
      const singaporePrSinceText = $('.singapore-pr-since-wrapper .datepicker[type="text"]');
      const nationalitySelection = $('#nationality-selection');

      this.clientId = target.value ? target.value : $('body').data('client-id');

      $.get(
        Routes.auto_fill_checkbox_by_selected_client_consultant_client_incomes_path({ client_id: this.clientId }),
      ).done((data) => {
        incomeIsForeigner.prop('checked', data.is_foreigner);
        incomeIsSelfEmployedSingaporean.prop('checked', data.is_self_employed);
        incomeHasSingaporePr.prop('checked', data.has_country_pr);
        nationalitySelection.val(data.nationality);

        const formatedDate = formatDate(new Date(data.singapore_pr_since));
        singaporePrSinceInput.val(formatedDate);
        singaporePrSinceText.val(formatedDate);

        this.checkboxLogicIncomeSalary();
        this.ajaxCallToCalculatePrepareMonthlyContribution();
      });
    });
  }
}

Raicon.register('consultant/cashflows', ConsultantCashflowsController);

import Raicon from 'raicon';
import { toggleTdTable, checkboxSelected } from '@/layouts/application/utils/multipleSelectTable';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import TaskForm from '@/controllers/consultant/tasks/formTask';
import AppointmentForm from '../planners/appointmentForm';
import { securityText } from '@/layouts/application/utils/securityText';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { formatMoneyToNumber } from '@/layouts/application/utils/formats';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';
import InvestmentIllustratorChart from '../investment_illustrators/investmentIllustratorChart';
import { add10Referees } from '../add10Referees';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';
import FilterForm from './filterForm';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { handleChangeContactType } from '../shared/handleChangeContactType';
import ImportContactForm from '@/layouts/application/utils/dropzone/importContactForm';
import ImportInsurancePoliciesForm from '@/layouts/application/utils/dropzone/importInsurancePoliciesForm';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';
import ContactForm from './contactForm';

class ConsultantClientsController {
  index() {
    checkboxSelected();
    toggleTdTable();
    clearInputNumberOnFocus();
    this.initSecurity();
    this.addTriggerOpenModalEvents();
    this.hiddenSearchDropSelect2('#labels_filter');
    this.eventForToggleContactListTable();
    this.eventForToggleSecurityTable();
    changeParamenterByTab('', 'active_tab');
    this.setupTaskForm();
    this.setupAppointmentForm();
    this.initDropZone();

    copyExecuted('#copy-pre-find-fact-link', '#pre-fact-find-link');
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-post-quiz-friendship-compatibility-link', '#post-quiz-friendship-compatibility-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    copyExecuted('#copy-client-link', '#client-portal-link');

    changeParamenterByTab('people', 'people_tab', true);
    handleMoneyField();
    handleTrackClickBanner();
    this.setupFilterForm();
    this.setupContactForm();
    handleChangeContactType();
  }

  initDropZone() {
    new ImportContactForm().setup();
    new ImportInsurancePoliciesForm().setup();
  }

  investmentIllustratorChart() {
    new GiftingCalculatorClientForm().setup();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    let clientID = $('#investment-illustrator-form').data('client-id');
    let clientDoB = new Date($('#investment-illustrator-form').data('client-dob'));
    let existTopupValue = this.getExistValueTopup();
    let existWithdrawalValue = this.getExistValueWithdrawal();

    let investment_illustrator_chart = new InvestmentIllustratorChart($('#investment-illustrator-chart-container'));
    investment_illustrator_chart.setup();
    this.setAgeYearCalculateEvent(clientDoB);

    if ($('.lumpsum-topup').length > 0) {
      for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
        $(`#lumpsum_topup_${i}`).on('change', () => {
          existTopupValue.push({
            age: Number($(`#topup_age_value_${i}`).val()),
            value: formatMoneyToNumber($(`#topup_amount_${i}`).val()),
            topup_id: i,
          });

          this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          investment_illustrator_chart.renderChartBaseOnType();
        });
      }

      $('.add-new-lumpsum-topup').on('click', () => {
        for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
          $(`#lumpsum_topup_${i}`).on('change', () => {
            existTopupValue.push({
              age: Number($(`#topup_age_value_${i}`).val()),
              value: formatMoneyToNumber($(`#topup_amount_${i}`).val()),
              topup_id: i,
            });

            this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          });
        }
      });
    } else {
      $('.add-new-lumpsum-topup').on('click', () => {
        for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
          $(`#lumpsum_topup_${i}`).on('change', () => {
            existTopupValue.push({
              age: Number($(`#topup_age_value_${i}`).val()),
              value: formatMoneyToNumber($(`#topup_amount_${i}`).val()),
              topup_id: i,
            });

            this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          });
        }
      });
    }

    if ($('.lumpsum-withdrawal').length > 0) {
      for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
        $(`#lumpsum_withdrawal_${i}`).on('change', () => {
          existWithdrawalValue.push({
            age: Number($(`#withdrawal_age_value_${i}`).val()),
            value: formatMoneyToNumber($(`#withdrawal_amount_${i}`).val()),
            withdrawal_id: i,
          });

          this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          investment_illustrator_chart.renderChartBaseOnType();
        });
      }

      $('.add-new-lumpsum-withdrawal').on('click', () => {
        for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
          $(`#lumpsum_withdrawal_${i}`).on('change', () => {
            existWithdrawalValue.push({
              age: Number($(`#withdrawal_age_value_${i}`).val()),
              value: formatMoneyToNumber($(`#withdrawal_amount_${i}`).val()),
              withdrawal_id: i,
            });

            this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          });
        }
      });
    } else {
      $('.add-new-lumpsum-withdrawal').on('click', () => {
        for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
          $(`#lumpsum_withdrawal_${i}`).on('change', () => {
            existWithdrawalValue.push({
              age: Number($(`#withdrawal_age_value_${i}`).val()),
              value: formatMoneyToNumber($(`#withdrawal_amount_${i}`).val()),
              withdrawal_id: i,
            });

            this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
          });
        }
      });
    }

    const that = this;

    $('#additional-information').on(
      'click',
      '.remove-lumpsum-topup-button, .remove-lumpsum-withdrawal-button',
      function (e) {
        let existTopupValue = that.getExistValueTopup();
        let existWithdrawalValue = that.getExistValueWithdrawal();

        that.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
      },
    );

    $(
      '#investment_illustrator_age_from, #investment_illustrator_age_to, #investment_illustrator_deposit_amount, #investment_illustrator_investment_rate_return, #investment_illustrator_deposit_start_age, #investment_illustrator_deposit_end_age, #investment_illustrator_payout_type, #investment_illustrator_yearly_payout_amount, #investment_illustrator_inflation_checkbox, #investment_illustrator_inflation_rate, #investment_illustrator_dividend_yield, #investment_illustrator_dividend_check, .lumpsum-topup-content, .lumpsum-withdrawal-content',
    ).on('change', () => {
      let existTopupValue = that.getExistValueTopup();
      let existWithdrawalValue = that.getExistValueWithdrawal();
      this.updateInvestmentIllustratorChartOfClient(clientID, existTopupValue, existWithdrawalValue);
    });
  }

  updateInvestmentIllustratorChartOfClient(clientID, lumpsumTopupValue, lumpsumWithdrawalValue) {
    $.post(Routes.update_investment_illustrator_chart_consultant_client_path({ id: clientID }), {
      age_start: $('#investment_illustrator_age_from').val(),
      age_end: $('#investment_illustrator_age_to').val(),
      deposit_amount_monthly: formatMoneyToNumber($('#investment_illustrator_deposit_amount').val()),
      investment_rate_return: $('#investment_illustrator_investment_rate_return').val(),
      deposit_start_age: $('#investment_illustrator_deposit_start_age').val(),
      deposit_end_age: $('#investment_illustrator_deposit_end_age').val(),
      payout_type: $('#investment_illustrator_payout_type').val(),
      payout_start_age: $('#investment_illustrator_payout_start_age').val(),
      payout_end_age: $('#investment_illustrator_payout_end_age').val(),
      payout_amount_yearly: formatMoneyToNumber($('#investment_illustrator_yearly_payout_amount').val()),
      inflation_checkbox: $('#investment_illustrator_inflation_checkbox').is(':checked'),
      inflation_rate: $('#investment_illustrator_inflation_rate').val(),
      dividend_yield: $('#investment_illustrator_dividend_yield').val(),
      reinvest: $('#investment_illustrator_dividend_check').is(':checked'),
      lumpsum_topup: lumpsumTopupValue,
      lumpsum_withdrawal: lumpsumWithdrawalValue,
    });
  }

  setAgeYearCalculateEvent(clientDoB) {
    const currentYear = new Date().getFullYear();
    const clientAge = getAge(clientDoB);

    const setYearForAgeTarget = (targetElementSelector, ageValue) => {
      const inputedAge = parseInt(ageValue);
      const yearResult = currentYear + (inputedAge - clientAge);
      $(targetElementSelector).html(yearResult);
    };

    setYearForAgeTarget($('.client-age-from'), $('#investment_illustrator_age_from').val());
    setYearForAgeTarget($('.client-age-to'), $('#investment_illustrator_age_to').val());
    setYearForAgeTarget($('.deposit-age-from'), $('#investment_illustrator_deposit_start_age').val());
    setYearForAgeTarget($('.deposit-age-to'), $('#investment_illustrator_deposit_end_age').val());
    setYearForAgeTarget($('.payout-age-from'), $('#investment_illustrator_payout_start_age').val());
    setYearForAgeTarget($('.payout-age-to'), $('#investment_illustrator_payout_end_age').val());

    $('#investment_illustrator_age_from').on('input', ({ target }) => {
      setYearForAgeTarget($('.client-age-from'), target.value);
    });

    $('#investment_illustrator_age_to').on('input', ({ target }) => {
      setYearForAgeTarget($('.client-age-to'), target.value);
    });

    $('#investment_illustrator_deposit_start_age').on('input', ({ target }) => {
      setYearForAgeTarget($('.deposit-age-from'), target.value);
    });

    $('#investment_illustrator_deposit_end_age').on('input', ({ target }) => {
      setYearForAgeTarget($('.deposit-age-to'), target.value);
    });

    $('#investment_illustrator_payout_start_age').on('input', ({ target }) => {
      setYearForAgeTarget($('.payout-age-from'), target.value);
    });

    $('#investment_illustrator_payout_end_age').on('input', ({ target }) => {
      setYearForAgeTarget($('.payout-age-to'), target.value);
    });
  }

  getExistValueTopup() {
    let initialTopupValue = [];
    if ($('.lumpsum-topup').length > 0) {
      for (let i = 1; i < $('.lumpsum-topup').length + 1; i++) {
        initialTopupValue.push({
          age: Number($(`#topup_age_value_${i}`).val()),
          value: formatMoneyToNumber($(`#topup_amount_${i}`).val()),
          topup_id: i,
        });
      }
    }
    return initialTopupValue;
  }

  getExistValueWithdrawal() {
    let initialWithdrawalValue = [];
    if ($('.lumpsum-withdrawal').length > 0) {
      for (let i = 1; i < $('.lumpsum-withdrawal').length + 1; i++) {
        initialWithdrawalValue.push({
          age: Number($(`#withdrawal_age_value_${i}`).val()),
          value: formatMoneyToNumber($(`#withdrawal_amount_${i}`).val()),
          withdrawal_id: i,
        });
      }
    }
    return initialWithdrawalValue;
  }

  initSecurity() {
    if ($('.contact-index-page').data('is-security') === 'true' || $('.contact-index-page').data('is-security')) {
      securityText('.country-code');
      securityText('.email-field', 'email');
      securityText('.mobile-field');
    }
  }

  eventForToggleSecurityTable() {
    $(document).on('click', '#hide-security-table-button, #show-security-table-button', function() {
      const securityButton = $(this);
      securityButton.prop('disabled', true);

      $.post(Routes.toggle_security_table_consultant_clients_path({ type: 'contacts' }), function() {
        window.location.reload();
      }).fail(function() {
        securityButton.prop('disabled', false);
      });
    });
  }

  eventForToggleContactListTable() {
    $('body').on('click', '#hide-contact-list-button', () => {
      $.post(Routes.hide_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').addClass('d-none');
        $('#hide-contact-list-content').removeClass('d-none');
      });
    });

    $('body').on('click', '#show-contact-list-button', () => {
      $.post(Routes.show_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').removeClass('d-none');
        $('#hide-contact-list-content').addClass('d-none');
      });
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#create-tags', () => {
      // this.hiddenSearchDropSelect2('#labels_labels');
      this.addIdsToAddLabelsAndUpdateRating();
    });

    $('body').on('click', '#bulk-update-rating', () => {
      this.addIdsToAddLabelsAndUpdateRating();
    });

    $('body').on('click', '#bulk-update-lead', () => {
      this.addIdsToAddLabelsAndUpdateContentType();
    });

    $('body').on('click', '#new-referee-to-contact-button', () => {
      changePrWhenNationalityIsSingapore();
    });
  }

  addIdsToAddLabelsAndUpdateRating() {
    const selectBox = $('.select-box:checked');
    const labelInputIds = $('#create-labels-client-ids');
    const ratingInputIds = $('#bulk-update-rating-client-ids');
    let ids = [];

    selectBox.each(function () {
      const value = $(this).data('value');
      ids.push(value);
    });

    labelInputIds.val(ids);
    ratingInputIds.val(ids);
  }

  addIdsToAddLabelsAndUpdateContentType() {
    const selectBox = $('.select-box:checked');
    const labelInputIds = $('#bulk-update-lead-client-ids');
    const ratingInputIds = $('#bulk-update-lead-modal');
    let ids = [];

    selectBox.each(function () {
      const value = $(this).data('value');
      ids.push(value);
    });

    labelInputIds.val(ids);
    ratingInputIds.val(ids);
  }

  hiddenSearchDropSelect2(id) {
    $(id)
      .select2({
        tags: true,
        allowClear: false,
        tokenSeparators: [';', '	', ' '],
      })
      .on('select2:open', function (e) {
        $('.select2-container--open .select2-dropdown--below').css('display', 'none');
      });
  }

  show() {
    console.log('Script for `app/views/consultant/clients/show.slim`');
  }

  setupTaskForm() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task', () => {
      new TaskForm().setup();
    });
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }

  setupFilterForm() {
    $('#show-filter-modal').on('click', () => {
      if ($('#filter-modal').data('setup-finished') != true) {
        new FilterForm().setup();
      }
    })
  }

  setupContactForm() {
    $('body').on('modal:afterSetContent', '#open-new-contact, #new-contact-form, #edit-contact-button', () => {
      new ContactForm().setup();
      changePrWhenNationalityIsSingapore();
    });
  }
}

Raicon.register('consultant/clients', ConsultantClientsController);
